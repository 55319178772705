.tableStandard{
  margin: 0.5em 0;

  th{
    font-weight: bold !important;
  }

  thead{
    font-weight: bold;
  }
}
