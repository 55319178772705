$width: 512px;
$twidth: 900px;

$PrimaryColor: #26c485;
$SecondaryColor: #16817e;
$ForegroundColor: #284b63;
$BackgroundColor: #153243;
$TextColor: #fefdff;

@mixin Mobile {
  @media (max-width: $width) {
    @content;
  }
}

@mixin Tablet {
  @media (min-width: $width) and (max-width: $twidth) {
    @content;
  }
}

body,
html {
  background-color: $BackgroundColor;
  font-family: "Roboto", sans-serif;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
  height: 100vh;
  width: 100vw;

  #Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .Box {
      background-color: $ForegroundColor;
      border-top: 6px solid $PrimaryColor;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      padding: 2em;

      h1 {
        font-weight: bold;
        font-size: xx-large;
      }

      a {
        text-decoration: none !important;
      }

      .italic {
        font-style: italic;
      }
    }
  }
}
