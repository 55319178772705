@import "../../Global.scss";

.grid {
  grid-gap: 1.5em;
  padding: 0 3em;

  @include Mobile {
    padding: 0;
  }

  @include Tablet {
    padding: 0;
  }

  a {
    text-decoration: none !important;
  }

  .gridItem {
    display: flex;
    flex-direction: column;
    background-color: $ForegroundColor;
    border-top: 6px solid $PrimaryColor;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    max-width: none;
    padding: 0 !important;
    cursor: pointer;
    transition: margin 0.2s ease-in-out;

    img {
      width: 256px;
      height: 192px;
      box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
        0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);

      @include Mobile {
        width: 384px;
        height: 256px;
      }
    }

    h1 {
      font-weight: bold;
      font-size: x-large;
      margin: 0.5em 0;
    }

    &:hover {
      margin-top: -0.5em !important;
    }
  }
}
