@import "../../Global.scss";

.mockups {
  display: grid;
  grid-template-columns: 49% 49%;
  justify-content: space-between;
  height: 85vh;
  width: 90vw;
  overflow-y: auto;

  @include Mobile {
    padding: 0 1em;
    width: 100vh;
    height: max-content;
    grid-template-columns: 100%;
  }

  @include Tablet {
    padding: 0 2em;
    width: 100vh;
    height: max-content;
    grid-template-columns: 100%;
  }

  .Box {
    margin-bottom: 2em;
    padding: 0 !important;
  }

  .mockupContainer {
    display: flex;
    flex-direction: column;

    .mockupTop {
      display: flex;
      flex-direction: row;
      padding: 2em;
    }

    .mockupBottom {
      height: 256px;

      img {
        height: 256px;
        box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
          0px 3px 4px 0px rgba(0, 0, 0, 0.14),
          0px 1px 8px 0px rgba(0, 0, 0, 0.12);
      }
    }
  }
}
