@import "../../Global.scss";

.schedule {
  display: flex;
  flex-direction: column;
  height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;

  @include Mobile {
    margin: 0 1em;
    height: fit-content;
    overflow-y: hidden;
  }

  @include Tablet {
    margin: 0 2em;
    height: fit-content;
    overflow-y: hidden;
  }

  #scheduleElement {
    .vertical-timeline-element-content {
      border-top: 5px solid $PrimaryColor;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    p {
      color: $BackgroundColor;
    }

    span {
      color: #fff;
      font-size: large;
    }
  }
}
