@import "../../Global.scss";

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 70px);
  margin-top: -2em;

  @include Mobile {
    padding-top: 1em;
  }

  @include Tablet {
    width: 100vw;
  }

  #aboutContainer {
    width: 80vw;
    margin-bottom: 2em;
    text-align: center;

    @include Tablet {
      width: 80vw;
    }

    #aboutText {
      text-align: center;
      margin: 0 2em;
    }
  }

  .aboutMembers {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @include Mobile {
      display: none;
    }

    @include Tablet {
      flex-direction: column;
    }

    .Box {
      margin: 0 1em;
      width: calc(40vw - 3em);

      .aboutRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .aboutElement {
          color: $TextColor;
          h2 {
            display: flex;
            align-items: center;
            color: $TextColor;
            font-size: large;
            margin-bottom: -0.25em;

            span {
              background-color: $PrimaryColor;
              color: $TextColor;
              border-radius: 6px;
              padding: 0 0.5em;
              font-size: smaller;
            }
          }

          img {
            object-fit: cover;
            width: 192px;
            height: 192px;
            border-radius: 6px;
          }
        }
      }

      @include Tablet {
        margin: 1em 0;
        width: 80vw;
      }
    }
  }

  .Box {
    padding: 0 2em;
  }
}

h1 {
  font-size: 3rem;
  font-weight: 700;
  color: $PrimaryColor;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 2rem;
  font-weight: 700;
  color: $PrimaryColor;
  margin-bottom: 1.5rem;
}
