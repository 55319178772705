@import "../../Global.scss";
@import "~video-react/styles/scss/video-react";

.media {
  display: grid;
  grid-template-columns: 49% 49%;
  justify-content: space-between;
  height: 85vh;
  width: 90vw;
  overflow-y: auto;

  @include Mobile {
    padding: 0 1em;
    width: 100vh;
    height: max-content;
    grid-template-columns: 100%;
  }

  @include Tablet {
    padding: 0 2em;
    width: 100vh;
    height: max-content;
    grid-template-columns: 100%;
  }

  .Box {
    margin-bottom: 2em;
    height: fit-content;
    padding: 0 !important;
    background-color: yellow;

    @include Mobile {
      margin-bottom: 1em;
    }
  }
}

#audio {
  grid-template-columns: 32% 32% 32%;
  width: 95vw;

  @include Mobile {
    padding: 0 1em;
    width: 100vh;
    height: max-content;
    grid-template-columns: 100%;
  }

  @include Tablet {
    padding: 0 2em;
    width: 100vh;
    height: max-content;
    grid-template-columns: 100%;
  }
}

.mediaContainer {
  display: flex;
  flex-direction: column;

  .mediaTop,
  .mediaBottom {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .mediaTop {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
    }
  }

  .mediaBottom {
    padding: 1em;
    width: 90%;
    color: $TextColor;

    h1 {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    p {
      margin-top: 0.5em;
    }

    span {
      margin-left: 1em;
      padding: 0.25em 0.5em;
      border-radius: 5px;
      font-size: small;
      font-weight: bold;
      color: $TextColor;
    }

    #positive {
      background-color: $PrimaryColor;
    }

    #negative {
      background-color: crimson;
    }

    .features {
      display: flex;
      flex-direction: row;
      margin-top: 0.5em;
      align-items: center;
      margin-left: -1em;
    }
  }
}

#image {
  .mediaContainer {
    .mediaTop {
      height: 512px;
    }

    .mediaBottom {
      height: 96px;
    }
  }
}

#video {
  .mediaContainer {
    .mediaTop {
      height: fit-content;
    }

    .mediaBottom {
      height: fit-content;
    }
  }
}

#audio {
  .mediaContainer {
    .mediaTop {
      padding: 1em 0;
    }

    .mediaBottom {
      margin-top: -1em;
      height: 150px;
    }
  }
}
