@import "../../Global.scss";

.aboutHeading {
  color: $PrimaryColor;
  margin-bottom: 0.5em !important;
}

#left {
  text-align: left;

  span {
    margin-right: 0.5em;
  }

  img {
    margin-left: 2em;
  }

  h2 {
    justify-content: flex-start;
  }
}

#right {
  text-align: right;

  span {
    margin-left: 0.5em;
  }

  img {
    margin-right: 2em;
  }

  h2 {
    justify-content: flex-end;
  }
}
