@import "../../Global.scss";

#Auth {
  min-width: 384px;
  padding: 2em 4em;

  @include Mobile {
    padding: 2em 3em;
    min-width: 256px;
  }

  .message {
    margin-top: -0.5em;
  }

  #form {
    margin-top: 2em;
  }

  .form {
    Label {
      color: $TextColor;
    }

    Input {
      color: $TextColor;
    }
  }

  .btns {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 2em;

    @include Mobile {
      margin-top: 1em;
      flex-direction: column;
    }

    Button,
    a {
      width: 47%;
      color: black;
      background-color: $PrimaryColor;

      @include Mobile {
        font-size: small;
        width: 100%;
      }
    }
  }
}

#secondaryBtn {
  color: $TextColor;
  background-color: $BackgroundColor;

  @include Mobile {
    margin-bottom: 1em;
  }
}
