@import "../../Global.scss";

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.background {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 64px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  padding-bottom: 64px;
  padding-top: 32px;

  @include Mobile {
    top: 56px;
    padding: 32px 0;
    overflow-y: auto;
  }

  @include Tablet {
    top: 56px;
    padding: 32px 0;
    overflow-y: auto;
  }
}

#ulList {
  li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 19s linear infinite;
    z-index: -1;

    @include Mobile {
      display: none;
    }

    @include Tablet {
      display: none;
    }
  }

  li:nth-child(0) {
    left: 14%;
    width: 8px;
    height: 8px;
    bottom: -8px;
    animation-delay: 1s;
  }
  li:nth-child(1) {
    left: 78%;
    width: 2px;
    height: 2px;
    bottom: -2px;
    animation-delay: 1s;
  }
  li:nth-child(2) {
    left: 57%;
    width: 12px;
    height: 12px;
    bottom: -12px;
    animation-delay: 10s;
  }
  li:nth-child(3) {
    left: 48%;
    width: 17px;
    height: 17px;
    bottom: -17px;
    animation-delay: 1s;
  }
  li:nth-child(4) {
    left: 82%;
    width: 10px;
    height: 10px;
    bottom: -10px;
    animation-delay: 2s;
  }
  li:nth-child(5) {
    left: 7%;
    width: 16px;
    height: 16px;
    bottom: -16px;
    animation-delay: 14s;
  }
  li:nth-child(6) {
    left: 44%;
    width: 17px;
    height: 17px;
    bottom: -17px;
    animation-delay: 12s;
  }
  li:nth-child(7) {
    left: 45%;
    width: 3px;
    height: 3px;
    bottom: -3px;
    animation-delay: 13s;
  }
  li:nth-child(8) {
    left: 36%;
    width: 4px;
    height: 4px;
    bottom: -4px;
    animation-delay: 23s;
  }
  li:nth-child(9) {
    left: 31%;
    width: 12px;
    height: 12px;
    bottom: -12px;
    animation-delay: 10s;
  }
  li:nth-child(10) {
    left: 34%;
    width: 15px;
    height: 15px;
    bottom: -15px;
    animation-delay: 25s;
  }
  li:nth-child(11) {
    left: 72%;
    width: 3px;
    height: 3px;
    bottom: -3px;
    animation-delay: 46s;
  }
  li:nth-child(12) {
    left: 53%;
    width: 13px;
    height: 13px;
    bottom: -13px;
    animation-delay: 23s;
  }
  li:nth-child(13) {
    left: 72%;
    width: 17px;
    height: 17px;
    bottom: -17px;
    animation-delay: 46s;
  }
  li:nth-child(14) {
    left: 11%;
    width: 1px;
    height: 1px;
    bottom: -1px;
    animation-delay: 64s;
  }
  li:nth-child(15) {
    left: 73%;
    width: 4px;
    height: 4px;
    bottom: -4px;
    animation-delay: 75s;
  }
  li:nth-child(16) {
    left: 4%;
    width: 4px;
    height: 4px;
    bottom: -4px;
    animation-delay: 35s;
  }
  li:nth-child(17) {
    left: 44%;
    width: 1px;
    height: 1px;
    bottom: -1px;
    animation-delay: 74s;
  }
  li:nth-child(18) {
    left: 83%;
    width: 9px;
    height: 9px;
    bottom: -9px;
    animation-delay: 15s;
  }
  li:nth-child(19) {
    left: 75%;
    width: 17px;
    height: 17px;
    bottom: -17px;
    animation-delay: 79s;
  }
  li:nth-child(20) {
    left: 52%;
    width: 6px;
    height: 6px;
    bottom: -6px;
    animation-delay: 40s;
  }
  li:nth-child(21) {
    left: 57%;
    width: 14px;
    height: 14px;
    bottom: -14px;
    animation-delay: 48s;
  }
  li:nth-child(22) {
    left: 3%;
    width: 11px;
    height: 11px;
    bottom: -11px;
    animation-delay: 3s;
  }
  li:nth-child(23) {
    left: 64%;
    width: 10px;
    height: 10px;
    bottom: -10px;
    animation-delay: 52s;
  }
  li:nth-child(24) {
    left: 38%;
    width: 10px;
    height: 10px;
    bottom: -10px;
    animation-delay: 4s;
  }
  li:nth-child(25) {
    left: 52%;
    width: 5px;
    height: 5px;
    bottom: -5px;
    animation-delay: 37s;
  }
  li:nth-child(26) {
    left: 84%;
    width: 10px;
    height: 10px;
    bottom: -10px;
    animation-delay: 48s;
  }
  li:nth-child(27) {
    left: 5%;
    width: 5px;
    height: 5px;
    bottom: -5px;
    animation-delay: 15s;
  }
  li:nth-child(28) {
    left: 47%;
    width: 14px;
    height: 14px;
    bottom: -14px;
    animation-delay: 63s;
  }
  li:nth-child(29) {
    left: 72%;
    width: 15px;
    height: 15px;
    bottom: -15px;
    animation-delay: 108s;
  }
  li:nth-child(30) {
    left: 3%;
    width: 5px;
    height: 5px;
    bottom: -5px;
    animation-delay: 150s;
  }
  li:nth-child(31) {
    left: 25%;
    width: 7px;
    height: 7px;
    bottom: -7px;
    animation-delay: 74s;
  }
  li:nth-child(32) {
    left: 10%;
    width: 7px;
    height: 7px;
    bottom: -7px;
    animation-delay: 157s;
  }
  li:nth-child(33) {
    left: 13%;
    width: 13px;
    height: 13px;
    bottom: -13px;
    animation-delay: 60s;
  }
  li:nth-child(34) {
    left: 52%;
    width: 8px;
    height: 8px;
    bottom: -8px;
    animation-delay: 83s;
  }
  li:nth-child(35) {
    left: 31%;
    width: 10px;
    height: 10px;
    bottom: -10px;
    animation-delay: 131s;
  }
  li:nth-child(36) {
    left: 80%;
    width: 5px;
    height: 5px;
    bottom: -5px;
    animation-delay: 135s;
  }
  li:nth-child(37) {
    left: 83%;
    width: 17px;
    height: 17px;
    bottom: -17px;
    animation-delay: 30s;
  }
  li:nth-child(38) {
    left: 22%;
    width: 16px;
    height: 16px;
    bottom: -16px;
    animation-delay: 16s;
  }
  li:nth-child(39) {
    left: 56%;
    width: 16px;
    height: 16px;
    bottom: -16px;
    animation-delay: 172s;
  }
  li:nth-child(40) {
    left: 75%;
    width: 15px;
    height: 15px;
    bottom: -15px;
    animation-delay: 157s;
  }
  li:nth-child(41) {
    left: 68%;
    width: 1px;
    height: 1px;
    bottom: -1px;
    animation-delay: 189s;
  }
  li:nth-child(42) {
    left: 28%;
    width: 3px;
    height: 3px;
    bottom: -3px;
    animation-delay: 13s;
  }
  li:nth-child(43) {
    left: 0%;
    width: 2px;
    height: 2px;
    bottom: -2px;
    animation-delay: 89s;
  }
  li:nth-child(44) {
    left: 15%;
    width: 3px;
    height: 3px;
    bottom: -3px;
    animation-delay: 92s;
  }
  li:nth-child(45) {
    left: 12%;
    width: 17px;
    height: 17px;
    bottom: -17px;
    animation-delay: 63s;
  }
  li:nth-child(46) {
    left: 17%;
    width: 2px;
    height: 2px;
    bottom: -2px;
    animation-delay: 58s;
  }
  li:nth-child(47) {
    left: 72%;
    width: 17px;
    height: 17px;
    bottom: -17px;
    animation-delay: 80s;
  }
  li:nth-child(48) {
    left: 13%;
    width: 13px;
    height: 13px;
    bottom: -13px;
    animation-delay: 9s;
  }
  li:nth-child(49) {
    left: 14%;
    width: 17px;
    height: 17px;
    bottom: -17px;
    animation-delay: 195s;
  }
}
