@import "../../Global.scss";

.styleguide {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 90vw;

  @include Mobile {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
  }

  @include Tablet {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
  }

  .Box {
    display: flex;
    flex-direction: column;
    min-width: 20vw;

    @include Mobile {
      padding: 0 1em;
      margin: 1em 0;
      width: 70vw;
    }

    @include Tablet {
      padding: 0 2em;
      margin: 2em 0;
      width: 70vw;
    }

    .title {
      margin-bottom: 0.5em;
    }

    .spacer {
      margin-top: 1em;
    }

    .btns {
      Button {
        margin-bottom: 1em;
      }
    }
  }

  #Colors {
    margin: 0 2em;

    @include Mobile {
      margin: 0;
    }

    @include Tablet {
      margin: 0;
    }

    h3 {
      margin-bottom: 0.5em;
      padding: 0.25em 0.5em;
      border-radius: 3px;
    }
  }
}
