@import "../../Global.scss";

.legalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  @include Mobile {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
  }

  @include Tablet {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
  }

  .Box {
    width: 50%;

    @include Mobile {
      width: 80vw;
    }

    @include Tablet {
      width: 80vw;
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  .legalSection {
    margin-bottom: 1em;
    width: 90%;

    ul {
      margin: 0.25em 0;
      list-style: circle;

      li {
        color: $PrimaryColor;
        width: 80%;
        margin-left: 1em;

        span {
          color: $TextColor;
          font-style: italic;
        }
      }
    }
  }

  #legalEnd {
    margin-bottom: 0;
  }

  #legalExamples {
    display: flex;
    flex-direction: column;
  }

  .legalHeading {
    color: $PrimaryColor;
    font-size: large;
    margin-bottom: 0.25em;
  }
}
