@import "../../Global.scss";

.Header {
  background-color: $ForegroundColor !important;

  .headerLogo {
    a {
      text-decoration: none;
    }
  }

  h5 {
    color: $PrimaryColor;

    @include Tablet {
      font-size: 0.75em;
    }
  }

  .headerLink {
    color: $TextColor;

    @include Mobile {
      display: none;
    }

    @include Tablet {
      display: none;
    }
  }

  .headerLink:hover {
    color: $PrimaryColor;
  }

  .headerBtn {
    background-color: $PrimaryColor;
    color: black;

    @include Mobile {
      display: none;
    }

    @include Tablet {
      display: none;
    }

    &:hover {
      background-color: $PrimaryColor;
    }
  }
}

#btnStack {
  @include Mobile {
    display: none;
  }
}

.menuDropdown {
  ul {
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  i {
    margin-right: 0.5em;
  }

  @include Mobile {
    display: none;
  }

  @include Tablet {
    display: none;
  }

  .mobileMenuItem {
    background-color: $ForegroundColor;
    color: $TextColor;
    padding: 0.5em 1em;

    &:hover {
      color: $PrimaryColor;
    }
  }
}

#mobileMenuBtn {
  cursor: pointer;
  font-size: xx-large;
  color: $TextColor;
  display: none;

  @include Mobile {
    display: none;
  }

  @include Tablet {
    display: none;
  }
}

.menuMobile {
  display: none;

  @include Mobile {
    display: none;
  }

  .mobileMenuButton {
    background-color: $PrimaryColor;
    color: Black;
    padding: 0.5em 1em;

    &:hover {
      color: black;
    }
  }
}
